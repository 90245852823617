body {
	overflow: scroll;
}

/* Title */
.Recip_Title {
	margin: 30px 0px 10px 0px;
	text-align: center;
	font-family: monospace;
	font-size: 1.8em;
}

/* Menu */
.Recip_Menu {
	width: 15%;
	font-family: monospace;
	font-size: 1.2em;
	float: left;
	border-top: 1px solid #000000;
}
.Recip_Menu ul {
	padding: 0px 0px 0px 10px;
	list-style-type: none;
	list-style-position: inside;
}
.Recip_Menu ul li {
	padding: 4px;
	cursor: pointer;
	background-color: #ffffff;
}
.Recip_Menu ul li:hover {
	background-color: #eaeaea;
}
.Recip_History {
	border-top: 1px solid #000000;
	padding: 20px 0px 0px 0px;
	text-align: center;
}

/* Center Pane*/
.Recip_Center_Main {
	width: 70%;
	font-family: monospace;
	float: left;
	border-top: 1px solid #000000;
}
.Recip_List {
	margin: 25px 0px 0px 0px;
	list-style-type: none;
	list-style-position: inside;
}
.Recip_List li {
	background-color: #ffffff;
	cursor: pointer;
	padding: 4px 0px 4px 15px;
}
.Recip_List li:hover {
	background-color: #eaeaea;
}

/*Recipe Loaded*/
.Recip_Recipe_Title {
	text-align: center;
	font-family: monospace;
	font-size: 1.4em;
	padding: 5px 0px 0px 0px;
}
.Recip_Recipe_Image {
	text-align: center;
	width: 200px;
	height: 200px;
	margin: 0px 10px 0px 0px;
	float: right;
	border: 1px solid #000000;
}
.Recip_Recipe_Heading {
	text-decoration: underline;
	font-family: monospace;
	font-size: 1.2em;
	padding: 10px 0px 2px 10px;
}
.Recip_Recipe_Sub_Heading {
	text-decoration: underline;
	font-family: monospace;
	font-size: 1em;
	padding: 10px 0px 2px 15px;
}
.Recip_Recipe_Subsection {
	min-height: 200px;
}
.Recip_Recipe_List {
	font-size: 1em;
	text-align: left;
	padding: 0px 5px 0px 5px;
	width: 800px;
}
.Recip_Recipe_Ingredient {
	background-color: #ffffff;
	list-style-type: disc;
	list-style-position: inside;
	padding: 5px 5px 5px 10px;
	cursor: pointer;
}
.Recip_Recipe_Ingredient:hover {
	background-color: #eaeaea;
}
.Recip_Recipe_Text {
	font-size: 1em;
	text-align: left;
	padding: 0px 5px 0px 5px;
}
.Recip_Recipe_Instruction {
	background-color: #ffffff;
	padding: 5px 5px 5px 10px;
	cursor: pointer;
}
.Recip_Recipe_Instruction:hover {
	background-color: #eaeaea;
}
.Recip_Recipe_Tags {
	font-family: monospace;
	font-size: 1em;
	padding: 0px 0px 0px 15px;
}
.Recip_Recipe_Tag {
	background-color: #ffffff;
	cursor: pointer;
	padding: 2px 4px 2px 4px;
}
.Recip_Recipe_Tag:hover {
	background-color: #eaeaea;
}
.Recip_Recipe_Footer {
	text-align: center;
	width: 100%;
	font-family: monospace;
	font-size: 0.9em;
	margin: 10px 0px 5px 0px;
}
.Recip_Recipe_Author {
	background-color: #ffffff;
	cursor: pointer;
	font-weight: 900;
	padding: 4px;
}
.Recip_Recipe_Author:hover {
	background-color: #eaeaea;
}

/* Quick Search*/
.Recip_Qsearch {
	width: 15%;
	float: left;
	min-height: 600px;
	border-top: 1px solid #000000;
}
.Recip_Qsearch_Title {
	text-align: center;
	font-family: monospace;
	font-size: 1.1em;
	margin: 5px 0px 10px 0px;
}
.Recip_Qsearch_Search {
	text-align: center;
}
.Recip_Qsearch_Search_Text {
	width: 120px;
	font-family: monospace;
	margin: 0px 5px 15px 0px;
}
.Recip_Qsearch_Search_Go {
	width: 40px;
}

/* Footer */
.Recip_Footer {
	margin: 40px auto 10px auto;
	float: left;
	width: 100%;
	text-align: center;
	font-family: monospace;
	font-size: 1.2em;
  border-top: 1px solid #000000;
}
.Recip_Logout {
	padding: 5px;
}
.Recip_Logout:hover {
	cursor: pointer;
	background-color: #eaeaea;
}